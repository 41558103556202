import Highcharts, {Options} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from "react";

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);

Highcharts.setOptions({
    lang: {
        thousandsSep: '.',
        decimalPoint: ','
    }
});

export default function CommonChart({options, chartRef}: {options: Options, chartRef: React.RefObject<HighchartsReact.RefObject>}) {
    return <>
        <HighchartsReact
            highcharts={Highcharts}
            ref={chartRef}
            options={{
                ...options,
                accessibility: {
                    enabled: false
                },
                credits: undefined,
                title: {
                    text: null
                },
                exporting: {
                    ...options?.exporting,
                    enabled: false,
                },
            }}
        />
    </>;
};