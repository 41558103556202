import {Modal, Button, Row, Col, ModalProps} from 'antd'
import React from "react";

export default function ConfirmCancelModal(props: ModalProps) {
    return <Modal
        {...props}
        footer={[
            <Row justify={'space-between'} key={'modal-actions'}>
                <Col>
                    <Button
                        onClick={props.onCancel}
                        {...props.cancelButtonProps}
                    >
                        {props.cancelText ? props.cancelText : 'Annulla'}
                    </Button>
                </Col>
                <Col>
                    <Button
                        onClick={props.onOk}
                        type={'primary'}
                        {...props.okButtonProps}
                    >
                        {props.okText ? props.okText : 'Conferma'}
                    </Button>
                </Col>
            </Row>
        ]}
    />
}