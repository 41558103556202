import HighchartsReact from 'highcharts-react-official';
import CommonChart from "./CommonChart";
import React from "react";
import {Options} from "highcharts";

const BarPercentageChart = ({options, chartRef, boundRatios}: {options: Options, chartRef: React.RefObject<HighchartsReact.RefObject>, boundRatios?: boolean}) => {
    return <>
        <CommonChart
            chartRef={chartRef}
            options={{
                yAxis: {
                    visible: false
                },
                xAxis: {
                    visible: false
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    bar: {
                        pointWidth: 12,
                        dataLabels: {
                            x: 0,
                            y: -100,
                            align: 'left',
                            enabled: true,
                            style: {
                                fontWeight: 'bold',
                                fontSize: '24px'
                            },
                        },
                    }
                },
                chart: {
                    height: boundRatios ? 60 : 80,
                    margin: boundRatios ? [0,0,-10,0] : [0, 0, -24, 0],
                    spacing: [0, 0, 0, 0],
                },
                ...options
            }}
        />
    </>;
}

export default BarPercentageChart