import React, { Ref } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Card, Col, Row, RowProps, ColProps } from "antd";

export default function MakeTransition({ children, forwardedKey }: { children: React.ReactNode, forwardedKey: string }) {

    const variants = {
        hidden: { opacity: 0, x: -200, y: 0 },
        enter: { opacity: 1, x: 0, y: 0 },
        exit: { opacity: 0, x: 0, y: -100 },
    }


    return (
        <AnimatePresence >
            <motion.div
                key={forwardedKey}
                variants={variants} // Pass the variant object into Framer Motion 
                initial="hidden" // Set the initial state to variants.hidden
                animate="enter" // Animated state to variants.enter
                exit="exit" // Exit state (used later) to variants.exit
                transition={{ type: 'linear' }} // Set the transition to linear
            >
                {children}
            </motion.div>
        </AnimatePresence>
    )
}



// export const ForwardedAntdRow = React.forwardRef(({ props, ref }: { props: RowProps, ref: Ref<HTMLDivElement> }) => (
//     <Row ref={ref} {...props} />
// ));
// export const MotionRow = motion(ForwardedAntdRow);

// const ForwardedAntdCol = React.forwardRef(({ props, ref }: { props: ColProps, ref: Ref<HTMLDivElement> }) => (
//     <Col ref={ref} {...props} />
// ));

// export const MotionCol = motion(ForwardedAntdCol);