import {apiSlice} from "redux/api/baseApiSlice";
import {
    NegotiationReinsurerFormModel,
    NegotiationReinsurerFormModelPut,
    NegotiationReinsurerModel
} from "types/negotiations";
import {GetReinsurerModel, ReinsurerModel} from "types/reinsurer";

export const reinsurersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotPaginatedReinsurers: builder.query<ReinsurerModel[], { search?: string }>({
            query: (args) => {
                return {
                    url: '/negotiation/reinsurers/',
                    params: {
                        paginate: false,
                        ...args
                    }
                };
            },
        }),
        getPaginatedReinsures: builder.query<GetReinsurerModel, { search?: string, page?: number, page_size?: number }>({
            query: (args) => {
                return {
                    url: '/negotiation/reinsurers/',
                    params: {
                        paginate: true,
                        ...args
                    }
                };
            },
        }),
        createReinsurer: builder.mutation<ReinsurerModel, { name: string }>({
            query: ({name}) => {
                return {
                    url: '/negotiation/reinsurers/',
                    method: 'POST',
                    body: {name}
                }
            }
        }),
        getNegotiationReinsurers: builder.query<NegotiationReinsurerModel[], { negotiation_id: string }>({
            query: ({negotiation_id}) => `/negotiation/negotiations/${negotiation_id}/reinsurers_fee/`,
        }),
        addReinsurerToNegotiation: builder.mutation<NegotiationReinsurerModel, { negotiation_id: string, data: { reinsurer: string, fee: number | null } }>({
            query: ({negotiation_id, data}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/reinsurers_fee/`,
                    method: 'POST',
                    body: data
                }
            }
        }),
        removeReinsurerFromNegotiation: builder.mutation<void, { negotiation_id: string, reinsurer_id: string }>({
            query: ({negotiation_id, reinsurer_id}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/reinsurers_fee/${reinsurer_id}/`,
                    method: 'DELETE',
                }
            }
        }),
        updateReinsurerInNegotiation: builder.mutation<NegotiationReinsurerModel, { negotiation_id: string, reinsurer_id: string, data: Partial<NegotiationReinsurerFormModelPut> }>({
            query: ({negotiation_id, reinsurer_id, data}) => {
                return {
                    url: `/negotiation/negotiations/${negotiation_id}/reinsurers_fee/${reinsurer_id}/`,
                    method: 'PUT',
                    body: data
                }
            }
        }),
        updateReinsurerInSettings: builder.mutation<ReinsurerModel, { name: string, uuid: string }>({
            query: (args) => {
                const {name, uuid} = args
                return {
                    url: `/negotiation/reinsurers/${uuid}/`,
                    method: 'PATCH',
                    body: {name}
                }
            }
        }),
    })
})

export const {
    useLazyGetNotPaginatedReinsurersQuery,
    useLazyGetPaginatedReinsuresQuery,
    useCreateReinsurerMutation,
    useLazyGetNegotiationReinsurersQuery,
    useAddReinsurerToNegotiationMutation,
    useRemoveReinsurerFromNegotiationMutation,
    useUpdateReinsurerInNegotiationMutation,
    useUpdateReinsurerInSettingsMutation
} = reinsurersApiSlice