import { apiSlice } from "redux/api/baseApiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<{ token: string }, { email: string, password: string }>({
            query: (credentials) => ({
                url: '/get-token/',
                method: 'POST',
                body: { ...credentials }

            }),
        }),
    })
})

export const { useLoginMutation } = authApiSlice