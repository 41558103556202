import {Card, Col, Divider, Progress, Row, Statistic} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ChartContainer from "./ChartContainer";
import {useLazyGetPortfolioUploadedQuery} from "../../../redux/api/chartsApiSlice";
import BarPercentageChart from "../HighCharts/BarPercentageChart";
import HighchartsReact from "highcharts-react-official";
import {selectUser} from "../../../redux/features/userSlice";

const LoadedPolicies = (props: { chartRef: React.RefObject<HighchartsReact.RefObject> }) => {

    const title = 'Polizze caricate'
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetPortfolioUploadedQuery()
    const [total, setTotal] = useState<string>('')
    const [uploaded, setUploaded] = useState<string>('')
    const description = 'Il grafico mostra tra tutte le trattative in Bound, quante sono state caricate nel sistema portafoglio.'


    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data) {
            setTotal(data.total ? data.total.toString() : '0')
            setUploaded(data.uploaded ? data.uploaded.toString() : '0')
        }
    }, [data])

    return <ChartContainer title={title} loading={isLoading || isFetching} description={description}>
        <BarPercentageChart
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title,
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        chart: {
                            height: 150,
                            spacing: [10, 10, 15, 10],
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    x: 0,
                                    y: -25
                                },
                            },
                        },

                    },
                },
                series: [
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [1-(Number(uploaded)/Number(total))],
                        color: '#F5F5F5',
                        dataLabels: {
                            enabled: false,

                        },
                    },
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: Array.from({length: 1}, () => Number(uploaded)/Number(total)),
                        color: '#13C2C2',
                        dataLabels: {
                            enabled: true,
                            format: uploaded
                        },
                    },

                ]
            }}/>
        <Divider/>
        <Row justify={'space-between'}>
            <Col>
                Su un totale di
            </Col>
            <Col>
                <span style={{fontWeight: 'bold'}}>{total}</span>
            </Col>
        </Row>
    </ChartContainer>
}

export default LoadedPolicies