import {Select, Spin, SelectProps} from "antd";
import React, {useCallback, useEffect} from "react";
import {debounce} from "lodash";
import {useLazyGetDelegatedInsurerQuery} from "../../../redux/api/negotiationUsersApiSlice";

export default function DelegatedInsurerMultiSelect(props: SelectProps) {
    const [fetchDelegatedInsurers, {
        data: delegatedInsurers,
        isLoading: isDelegatedInsurersLoading,
        isFetching: isDelegatedInsurerFetching,
        isError: isDelegatedInsurersError
    }] = useLazyGetDelegatedInsurerQuery()


    const debounceFetcher = useCallback(debounce((searchTerm) => fetchDelegatedInsurers({search: searchTerm}), 600), [])

    useEffect(() => {
        fetchDelegatedInsurers({})
    }, [fetchDelegatedInsurers])

    return <Select
        labelInValue
        mode={'multiple'}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={isDelegatedInsurersLoading || isDelegatedInsurerFetching ?
            <Spin size="small"/> : 'Nessun risultato corrispondente a questa chiave di ricerca'}
        options={delegatedInsurers?.map(el => ({label: el.name, value: el.uuid})) || []}
        virtual={false}
        placeholder={'Digitare il nome della delegataria...'}
        {...props}
    />
}


