import ChartContainer from "./ChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import HighchartsReact from "highcharts-react-official";
import { Select} from "antd";
import PortfolioHistogram from "../HighCharts/PortfolioHistogram";
import {useLazyGetPortfolioBrokersQuery} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/features/userSlice";

export default function BrokerPortfolioChart(props: { chartRef: React.RefObject<HighchartsReact.RefObject>, className: string }) {

    const title = 'Portafoglio intermediario'
    const [categories, setCategories] = useState<string[]>([])
    const [series, setSeries] = useState<number[]>([])
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetPortfolioBrokersQuery()
    const description = 'Il grafico mostra la suddivisione delle polizze per Intermediario.'

    const select = <Select key={'select'} options={[{label: 'In attesa di integrazione', value: 0}]}
                           defaultValue={0}
                           bordered={false}
                           style={{backgroundColor: '#F9FBFF', color: '#7E7E7E', padding: '0.6rem 0.8rem',}}/>

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
                waiting_reason: 'NO'
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data) {
            setCategories(data.map((itm) => itm.name))
            setSeries(data.map((itm) => {
                return itm.negotiations
            }))
        }
    }, [data])

    return <ChartContainer
        description={description}
        loading={isLoading || isFetching}
        className={props.className}
        title={title}
        buttons={[select, <DownloadButton key={'download-button'} chartRef={props.chartRef}/>]}>
        <PortfolioHistogram
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        }
                    }
                },
                xAxis: {
                    type: "category",
                    categories: categories,
                    visible: true,
                    lineWidth: 0
                },
                series: [{
                    color: '#5932EA',
                    type: 'column',
                    data: categories.map((itm, index) => ({name: itm, y: series[index]})),
                }]
            }}
        />
    </ChartContainer>
}