import {Button, Modal, Timeline, Typography} from 'antd';
import React, {useState} from 'react';
import {QuestionCircleOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {selectOptions} from "../../redux/features/optionsSlice";

const {Text, Paragraph, Title} = Typography;

export default function StatusHelpModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const states = useSelector(selectOptions).states.filter(itm => itm.text !== 'Stornata')
    const descriptions = [
        <Text>Trattativa proveniente da Rore.</Text>,//RORE
        <Text>Stato iniziale di una trattativa.</Text>,//REPORTED
        <Text>Stato in cui una trattativa è assegnata ad un Underwriter.</Text>,//ASSEGNATA
        //WORKING
        <>
            <Text>Stato in cui la trattativa viene elaborata. Si raccolgono informazioni e si
                eseguono tutte le considerazioni necessarie per giungere alla quotazione della
                submission o al rifiuto di prendere in carico la richiesta.<br/>
                Gli stati successivi possibili sono:
                <ul>
                    <li>Declined</li>
                    <li>Quoted</li>
                    <li>Referred</li>
                </ul>
            </Text>
        </>,
        //QUOTED
        <Text>La richiesta di quotazione ha trovato l’interesse della Compagnia ed il lavoro per
            la quotazione si è concluso. Si resta in attesa che il richiedente accetti o rifiuti la
            quotazione proposta. <br/>
            Gli stati successivi possibili sono:
            <ul>
                <li>Bound</li>
                <li>Not Taken Up.</li>
            </ul>
        </Text>,
        //DECLINED
        <Text>
            La richiesta di quotazione non trova l’interesse della Compagnia. La trattativa muore.
        </Text>,
        //BOUND
        <Text>
            La Quotazione è stata accettata dal Broker. Lo stato successivo è Draft.
        </Text>,
        //NOT TAKEN UP
        <Text>
            La Quotazione non è stata accettata dal Broker/Cliente. La trattativa muore.
        </Text>,
        //DRAFT
        <Text>
            Questa fase è di pertinenza dell’ufficio Portafoglio. La trattativa è in lavorazione. Lo stato successivo è
            Issued.
        </Text>,
        //ISSUED
        <Text>
            Il lavoro dell’ufficio Portafoglio è terminato.
        </Text>,
        //IN RINNOVO
        <Text>
            Stato a cui la trattativa (che sia almeno arrivata allo stato di Bound) giunge
            automaticamente prima della scadenza della polizza che ha generato.
            Il tempo d’anticipo sulla scadenza della polizza in cui la trattativa passa In Rinnovo
            è:
            <ul>
                <li>polizza con rinnovo automatico (tacito rinnovo):<br/>30 giorni prima della scadenza della polizza
                </li>
                <li>Se la trattativa attiene ad una polizza senza tacito rinnovo: <br/>60 giorni prima della scadenza
                    della polizza
                </li>
            </ul>
            In questo lasso temporale l’UnderWriter, deve decidere se rinnovare (con o senza modifiche) o non rinnovare
            la polizza per l’anno successivo. Gli stati successivi possibili sono:
            <ul>
                <li>Rinnovata</li>
                <li>Non Rinnovata</li>
            </ul>
        </Text>,
        //RINNOVATA
        <Text>
            Stato a cui giungono le trattative che vengono rinnovate. Ci sono due possibilità:
            <ul>
                <li>Rinnovata senza modifiche. Possibile solo se la polizza in rinnovo è con tacito rinnovo, in questo
                    caso viene creata la nuova trattativa (polizza) per l’uw year successivo in stato Bound.
                </li>
                <li>
                    Rinnovata con modifiche. In questo caso viene creata la nuova trattativa per l’uw year successivo in
                    stato Working.
                </li>
            </ul>
        </Text>,
        //REFERRED
        <Text>
            L’assegnatario della Trattativa non dispone della authority sufficiente per
            prendere decisione in merito alla Trattativa. Rimanda la decisione in merito alla
            richiesta di quotazione al Responsabile di Linea o al Responsabile Corporate.
        </Text>,
        //REFERRAL APPROVED
        <Text>
            Il Responsabile di Linea o il Responsabile Corporate approvano quanto fatto dal
            richiedente Referral: la trattativa torna in stato Working, per il precedente assegnatario.
        </Text>,
        //REFERRAL DECLINED
        <Text>
            Il Responsabile di Linea o il Responsabile Corporate rifiutano di dar seguito alla
            trattiva. La trattativa muore.
        </Text>,
        //STORNATA,
        <Text></Text>,
        //NON RINNOVATA
        <Text>
            Stato a cui giungono le trattative relative a polizze che la Compagnia non intende
            rinnovare.
        </Text>
    ]

    const displayStatus = states.map((itm) => {
        // @ts-ignore
        return {...itm, description: descriptions[itm.value]}
    })

    return (
        <>
            <Button type="primary"
                    icon={<QuestionCircleOutlined
                        style={{fontSize: '18px', color: '#818A94'}}/>}
                    onClick={showModal}
                    style={{
                        borderColor: 'transparent',
                        backgroundColor: 'transparent',
                        boxShadow: "none",
                        cursor: 'pointer'
                    }}/>
            <Modal title="Descrizione stati trattativa" open={isModalOpen} onCancel={handleCancel} centered={true}
                   footer={null}>
                <Timeline style={{maxHeight: '600px', overflow: 'scroll', paddingTop: '16px'}}>
                    {displayStatus.map((itm, idx) => <Timeline.Item key={idx} color={itm.color}><Title level={5}
                                                                                      style={{paddingBottom: '0'}}>{itm.text}</Title>{itm.description}
                    </Timeline.Item>)}
                </Timeline>
            </Modal>
        </>
    );
};