import ChartContainer from "./ChartContainer";
import React, {useEffect, useState} from "react";
import DownloadButton from "components/buttons/DownloadButton";
import PremiumsNegotiationsSelect from "../../select/PremiumsNegotiationsSelect";
import NegotiationsDividedByStateTable from "../../tables/chartTables/NegotiationsDividedByStateTable";
import {
    useLazyGetUnderProcessingAgencyNegotiationsStatusChartQuery
} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import {TableDataType, TableHeader} from "../../../types/tableDataType";
import _ from "lodash";
import {selectUser} from "../../../redux/features/userSlice";

function Description({isNeg}: { isNeg: string }) {
    return <dl>
        <dd>La tabella mostra i dati relativi ai sale di agenzia relativi alla LOB selezionata.</dd>

        <dd>Il valore visualizzato è:</dd>
        {isNeg === 'true' ? <dd>Il numero delle trattative</dd> : <dd>La sommatoria dei premi delle trattative</dd>}
    </dl>
}

export default function UnderProcessingAgencyNegotiationsStatusChart(props: { className: string }) {

    const [isNegotiation, setIsNegotiation] = useState<string>('true')
    const [getData, {
        isLoading,
        isFetching,
        isError,
        data
    }] = useLazyGetUnderProcessingAgencyNegotiationsStatusChartQuery()
    const [dataWithTotal, setDataWithTotal] = useState<{ values: TableDataType[], header: TableHeader[] }>({
        values: [],
        header: []
    })

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value,
                is_negotiations: isNegotiation === 'true',
            })
        }
    }, [budgetGroup, getData, isNegotiation, isia, uwYear])

    useEffect(() => {
        if (data?.values && data?.header) {
            if (data.header.length > 2) {
                const headerCopy = _.cloneDeep(data.header)
                headerCopy.splice(1, 0, {
                    dataIndex: 'total',
                    title: 'Totale',
                    children: [
                        {dataIndex: 'totalWorking', title: 'Working', children: []},
                        {dataIndex: 'totalQuoted', title: 'Quoted', children: []}]
                })
                setDataWithTotal({
                    values: data.values.map(el => {
                        let totalWorking = 0
                        let totalQuoted = 0
                        Object.entries(el).forEach(([key, value], index) => {
                            if (key !== 'column0' && !(index % 2 === 0))
                                totalWorking += value as number
                            if (key !== 'column0' && index % 2 === 0)
                                totalQuoted += value as number
                        });
                        return {...el, totalWorking: totalWorking, totalQuoted: totalQuoted}
                    }),
                    header: headerCopy
                })
            } else {
                setDataWithTotal(data)
            }
        }

    }, [data, data?.header, data?.values])

    return <ChartContainer
        loading={isLoading || isFetching}
        description={<Description isNeg={isNegotiation}/>}
        className={props.className}
        title={'Stato trattative in lavorazione Agenzia'}
        buttons={[
            <PremiumsNegotiationsSelect key={'pn-select'} isNegotiation={isNegotiation}
                                        setIsNegotiation={setIsNegotiation}/>,
            <DownloadButton key={'download-button'}/>
        ]}>
        <NegotiationsDividedByStateTable negotiation={isNegotiation} data={dataWithTotal} isLoading={isLoading || isFetching}/>
    </ChartContainer>
}