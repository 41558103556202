import { apiSlice } from "redux/api/baseApiSlice";
import { InsurerModel, NegotiationLobUsers, NegotiationUserModel } from "types/negotiations";


export const negotiationUsersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNegotiationUsers: builder.query<NegotiationUserModel[], { page?: number, usertypes?: string }>({
            query: (args) => {
                const { page, usertypes } = args;
                return {
                    url: '/negotiation/negotiation_userprofile/',
                    //   params: { start, end },
                    params: args
                };
            },
        }),
        getNegotiationAttorneys: builder.query<NegotiationUserModel[], { negotiation_id: string }>({
            query: (args) => {
                const { negotiation_id } = args;
                return {
                    url: `negotiation/negotiations/${negotiation_id}/attorneys/`,
                };
            },
        }),
        getLobUsers: builder.query<NegotiationLobUsers, { id: string, ordering?: string }>({
            query: (args) => {
                const { id, ordering } = args;
                return {
                    url: `/negotiation/lobs/${id}`,
                    //   params: { start, end },
                    params: { ...(ordering && { ordering }) }
                };
            },
        }),
        getDelegatedInsurer: builder.query<InsurerModel[], { search?: string }>({
            query: (args) => {
                const { search } = args;
                return {
                    url: `/negotiation/delegatedinsurer/`,
                    params: { ...(search && { search }) }
                };
            },
        }),
        createDelegatedInsurer: builder.mutation<InsurerModel, { name: string }>({
            query: (args) => {
                const { name } = args;
                return {
                    url: `/negotiation/delegatedinsurer/`,
                    method: 'POST',
                    body: { name }
                };
            },
        }),
        updateDelegatedInsurer: builder.mutation<InsurerModel, { name: string, uuid: string }>({
            query: (args) => {
                const { name, uuid } = args;
                return {
                    url: `/negotiation/delegatedinsurer/${uuid}/`,
                    method: 'PATCH',
                    body: {name}
                }
            }
        })
    })
})

export const { useLazyGetNegotiationUsersQuery, useLazyGetNegotiationAttorneysQuery, useLazyGetLobUsersQuery, useLazyGetDelegatedInsurerQuery, useCreateDelegatedInsurerMutation, useUpdateDelegatedInsurerMutation } = negotiationUsersApiSlice