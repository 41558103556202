import {useLoginMutation} from "redux/api/authApiSlice"
import {Form, Input, Button, Card, Typography, Row, Col, Spin} from 'antd'

import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {motion} from "framer-motion";
import {useEffect} from "react";

const {Title} = Typography

type LoginFormType = {
    email: string,
    password: string

}
export default function Login() {

    const [form] = Form.useForm<LoginFormType>()
    const navigate = useNavigate()

    const [login, {isLoading: isLoginLoading}] = useLoginMutation()

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            navigate('/')
        }
    }, [navigate])

    const onFinish = async (values: LoginFormType) => {
        try {
            const res = await login(values).unwrap()
            localStorage.setItem('accessToken', res.token)
            navigate('/')
        } catch (e: any) {
            console.error('Login error', e)
            form.setFields([
                {
                    name: 'username',
                    errors: ['Credenziali non valide'],
                },
                {
                    name: 'password',
                    errors: ['Credenziali non valide'],
                },
            ]);
        }


    };

    return (
        <motion.div
            initial={{opacity: 0, x: 0, y: 0}}
            animate={{opacity: 1, x: 0, y: 0}}
            exit={{opacity: 0, x: 0, y: -100}}
            transition={{type: 'linear'}} // Set the transition to linear
        >
            <div className="login-form-container">
                <Card>
                    <Row justify='center' align='middle'>
                        <Col style={{width: '32px', height: '32px'}}>
                            <img alt={'Logo di Riskapp'} style={{width: '100%', display: 'block'}}
                                 src={process.env.PUBLIC_URL + '/img/logo.png'}/>
                        </Col>
                        <Col style={{paddingLeft: '8px'}}>
                            <Title level={4}>Login</Title>
                        </Col>
                    </Row>
                    <Form
                        form={form}
                        name="login"
                        layout="vertical"
                        requiredMark={false}
                        className="login-form"
                        style={{
                            marginTop: '24px'
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserisci il tuo username',
                                },
                            ]}
                        >
                            <Input type={'string'} prefix={<UserOutlined className="site-form-item-icon"/>}
                                   placeholder="username"/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label='Password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Inserisci la tua password',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon"/>}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item style={{marginTop: '24px'}}>
                            <Button style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}} type="primary"
                                    htmlType="submit" className="login-form-button">
                                {isLoginLoading ? <Spin/> : 'Login'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </motion.div>
    );
}