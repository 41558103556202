import {Select, SelectProps} from "antd";
import {Dispatch} from "react";

interface CustomSelect extends SelectProps {
    type: number;
    setType: Dispatch<1 | 2>
}

export default function SelectPremiumsOrNegotiationsCount(props: CustomSelect) {
    const {type, setType, ...selectProps} = props

    return <Select
        options={[
            {label: 'Trattative', value: 1},
            {label: 'Premi', value: 2}
        ]}
        style={{
            width: '7rem',
            textAlign: 'center'
        }}
        value={type}
        onSelect={(value: 1 | 2) => {
            props.setType(value)
        }}
        {...selectProps}
    />
}