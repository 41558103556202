import HighchartsReact from 'highcharts-react-official';
import CommonChart from "./CommonChart";
import React from "react";
import {Options} from "highcharts";

const AnnualBarChart = ({options, chartRef}: {options: Options, chartRef: React.RefObject<HighchartsReact.RefObject>}) => {
    return <>
        <CommonChart
            chartRef={chartRef}
            options={{
                xAxis: {
                    categories: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
                    gridLineWidth: 0,
                    tickWidth: 0,
                },
                yAxis: {
                    lineWidth: 0,
                    gridLineWidth: 0,
                    tickWidth: 0,
                    title: {
                        text: ''
                    },
                },
                chart: {
                    type: 'bar',
                },
                plotOptions: {
                    series: {
                        stacking: 'normal'
                    }
                },
                ...options
            }}
        />
    </>;
}

export default AnnualBarChart