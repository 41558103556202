import {Button, Divider} from "antd";
import React, {useEffect} from "react";
import {FilterDropdownProps} from "antd/lib/table/interface";
import {useDispatch} from "react-redux";
import {setOverlay} from "../../../redux/features/overlaySlice";

export default function FilterContainer(props: FilterDropdownProps & { children: React.ReactNode }) {

    const dispatch = useDispatch()

    useEffect(() => {
        props.visible? dispatch(setOverlay(true)) : dispatch(setOverlay(false))
    }, [dispatch, props.visible])

    return <div style={{padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        <div>
            {props.children}
        </div>
        <Divider style={{margin: 0}}/>
        <Button style={{maxWidth: '6rem'}} size={"small"} disabled={props.selectedKeys.length === 0}
                onClick={() => {
                    if (props.clearFilters) {
                        props.clearFilters()
                        props.confirm({closeDropdown: false});
                    }
                }}>
            Reset filtro
        </Button>
    </div>
}


