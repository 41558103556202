import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from 'redux/store'


const overlaySlice = createSlice({
    name: 'overlay',
    initialState: false,
    reducers: {
        setOverlay: (state, action: PayloadAction<boolean>) => {
            return action.payload
            // ❌ ERROR: does not actually mutate or return anything new!
            // state = {...action.payload}
        },
    }
})

export const {
    setOverlay
} = overlaySlice.actions

export default overlaySlice.reducer

export const selectOverlay = (state: RootState) => state.overlay