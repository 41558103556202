import {Card, Col, Divider, Progress, Row, Statistic} from "antd";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ChartContainer from "./ChartContainer";
import { useLazyGetPortfolioToBeUploadedQuery} from "../../../redux/api/chartsApiSlice";
import BarPercentageChart from "../HighCharts/BarPercentageChart";
import HighchartsReact from "highcharts-react-official";
import {selectUser} from "../../../redux/features/userSlice";

const PoliciesToLoad = (props: { chartRef: React.RefObject<HighchartsReact.RefObject> }) => {

    const title = 'Polizze da caricare'
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetPortfolioToBeUploadedQuery()
    const [policiesAmount, setPoliciesAmount] = useState<string>('')
    const [draft, setDraft] = useState<string>('')
    const description = 'Il grafico mostra tra tutte le trattative non ancora caricate, quante sono in stato Draft.'

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data) {
            setPoliciesAmount(data.to_be_uploaded ? data.to_be_uploaded.toString() : '0')
            setDraft(data.draft ? data.draft.toString() : '0')
        }
    }, [data])

    return <ChartContainer title={title} loading={isLoading || isFetching} description={description}>
        <BarPercentageChart
            chartRef={props.chartRef}
            options={{
                exporting: {
                    chartOptions: {
                        title: {
                            text: title,
                        },
                        subtitle: {
                            text: `Parametri: UW Year ${uwYear} - ${budgetGroup?.label} - ${isia?.label}`
                        },
                        chart: {
                            height: 150,
                            spacing: [10, 10, 15, 10],
                        },
                        plotOptions: {
                            bar: {
                                dataLabels: {
                                    enabled: true,
                                    x: 0,
                                    y: -25
                                },
                            },
                        },

                    },
                },
                series: [
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: [Number(draft) + Number(policiesAmount)],
                        color: '#F5F5F5',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                    {
                        type: 'bar',
                        stacking: 'percent',
                        data: Array.from({length: 1}, () => 100 - (Number(draft) / Number(policiesAmount) * 100)),
                        color: '#BEA8FB',
                        dataLabels: {
                            enabled: true,
                            format: policiesAmount
                        },
                    },

                ]
            }}/>
        <Divider/>
        <Row justify={'space-between'}>
            <Col>
                Di cui in bozza
            </Col>
            <Col>
                <span style={{fontWeight: 'bold'}}>{draft}</span>
            </Col>
        </Row>
    </ChartContainer>
}

export default PoliciesToLoad