import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { NegotiationUserModel } from 'types/negotiations';



const negotiationUsersSlice = createSlice({
    name: 'negotiationUsers',
    initialState: [] as NegotiationUserModel[],
    reducers: {
        setNegotiationUsers: (state, action: PayloadAction<NegotiationUserModel[]>) => {
            return action.payload
            // ❌ ERROR: does not actually mutate or return anything new!
            // state = {...action.payload}
        }
    }
})

export const { setNegotiationUsers } = negotiationUsersSlice.actions
export default negotiationUsersSlice.reducer

export const selectNegotiationUsers = (state: RootState) => state.userProfiles