import {useDispatch, useSelector} from "react-redux";
import {Select} from "antd";
import React from "react";
import {selectUser, setChartIsia} from "../../redux/features/userSlice";
import {useSetChartsParamsMutation} from "../../redux/api/userPreferencesApiSlice";

export default function IsiaSelect() {


    const dispatch = useDispatch()
    const isia = useSelector(selectUser).preferences?.charts_params?.isia
    const [updateChartsParams] = useSetChartsParamsMutation()

    const options = [
        {value: 'isia', label: 'Solo ISIA'},
        {value: 'no_isia', label: 'Solo No ISIA'},
        {value: 'all', label: 'ISIA e No ISIA'},
    ]

    return <Select
        labelInValue
        options={options}
        virtual={false}
        value={isia}
        onSelect={(option: any) => {
            dispatch(setChartIsia({label: option.label, value: option.value}))
            updateChartsParams({isia: {label: option.label, value: option.value}})

        }}
        style={{width: 120, textAlign: 'center'}}/>
}