import HighchartsReact from 'highcharts-react-official';
import CommonChart from "./CommonChart";
import React from "react";
import {Options} from "highcharts";


const Histogram = ({options, chartRef}: {options: Options, chartRef: React.RefObject<HighchartsReact.RefObject>}) => {
    return <>
        <CommonChart
            chartRef={chartRef}
            options={{
                legend: {
                    enabled: true
                },
                tooltip:{
                    headerFormat: '',
                },
                xAxis: {
                    visible: false,
                },
                yAxis: {
                    title: {
                        text: ''
                    }
                },
                ...options
            }}
        />
    </>;
}

export default Histogram