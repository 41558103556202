import {Select, SelectProps} from "antd";
import {Dispatch, useEffect} from "react";

interface CustomSelect extends SelectProps {
    isNegotiation: string;
    setIsNegotiation: Dispatch<string>
}

export default function PremiumsNegotiationsSelect(props: CustomSelect) {
    const {isNegotiation, setIsNegotiation, ...selectProps} = props
    return <Select
        options={[
            {label: 'Trattative', value: 'true'},
            {label: 'Premi', value: 'false'}
        ]}
        style={{
            width: '7rem',
            textAlign: 'center'
        }}
        defaultValue={'true'}
        onSelect={(select: string) => {
            props.setIsNegotiation(select)
        }}
        {...selectProps}
    />
}