import {useSelector} from "react-redux";
import {selectOptions} from "../../../../redux/features/optionsSlice";
import {Tag} from "antd";


export default function InstallmentTypeTag ({value} : {value: string} ) {
    const options = useSelector(selectOptions)
    const name = options.installmentTypes.find(el => el.value === value)?.text

    return name ? <Tag>{name}</Tag> : <></>
}


