import React, {useState} from "react";
import HighchartsReact from "highcharts-react-official";
import AnnualHitRatioChart from "./AnnualHitRatioChart";
import MonthlyHitRatioChart from "./MonthlyHitRatioChart";
import {Col, Form, Row, Select, Space} from "antd";
import Title from "antd/lib/typography/Title";

const {Option} = Select

export default function HitRatioContainer(props: { chartRef: React.RefObject<HighchartsReact.RefObject>[] }) {

    const [calculationType, setCalculationType] = useState<string>('1')

    return <>
        <Row className={'hit-ratio-header'} style={{marginTop: '1rem', marginBottom: '1rem'}} justify={"space-between"} align={'middle'}>
            <Col xs={{push: 1}} sm={{push: 0}}>
                <Title level={3}>Hit ratio e risultati</Title>
            </Col>
            <Col xs={{push: 1}} sm={{push: 0}}>
                <Row align={'middle'}>
                    <Space direction={'horizontal'} size={'small'}>
                        <label htmlFor={'calculationTypeSelect'}>Calcola per:</label>
                        <Form.Item name={'calculationTypeSelect'} style={{marginBottom: '0px'}}>
                            <Select defaultValue='1' onChange={(value: string) => setCalculationType(value)}
                                    style={{width: '10rem'}}>
                                <Option value='1'>Numero trattative</Option>
                                <Option value='2'>Premi</Option>
                            </Select>
                        </Form.Item>
                    </Space>

                </Row>
            </Col>
        </Row>


        <div className={'hit-ratio-container'} style={{marginTop: '-2rem'}}>

            <MonthlyHitRatioChart className={'monthly-hit-ratio--chart'} chartRef={props.chartRef[0]}
                                  calculationType={calculationType}/>
            <AnnualHitRatioChart className={'annual-hit-ratio--chart'} chartRef={props.chartRef[1]}
                                 calculationType={calculationType}/>
        </div>
    </>
}