export function RiskAppSpinner() {

    return (
        <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img width='75px' height={'75px'} style={{ animation: `spin ${5}s linear infinite` }} src={process.env.PUBLIC_URL + '/img/logo.png'} alt='loading...' />
        </div>
    )
}

export function RiskAppSpinnerInPage({ width, height }: { width: string, height: string }) {

    return (
        <div>
            <img width={width} height={height} style={{ animation: `spin ${5}s linear infinite` }} src={process.env.PUBLIC_URL + '/img/logo.png'} alt='loading...' />
        </div>
    )
}