import {Button, Input, Col, message, Row, Space} from "antd";
import {EditOutlined} from "@ant-design/icons";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Title from "antd/lib/typography/Title";

export default function EditName({
                                     id,
                                     text,
                                     updateText,
                                     isOneItemModifing,
                                     setIsOneItemModifing
                                 }: { id: string, text: string, updateText: (name: string) => Promise<boolean>, isOneItemModifing: { status: boolean; key: string; }, setIsOneItemModifing: Dispatch<SetStateAction<{ status: boolean; key: string; }>> }) {

    const [isEditing, setIsEditing] = useState(false)
    const [name, setName] = useState(text)
    const inputRef = useRef<any>(null)

    useEffect(() => {
        if ((isOneItemModifing.status && isOneItemModifing.key !== id) || isOneItemModifing.key === "")
            setIsEditing(false)
    }, [isOneItemModifing])

    useEffect(() => {
        setName(text)
    }, [text])

    useEffect(() => {
        if (isEditing)
            inputRef.current.focus({cursor: 'all'})
    }, [isEditing])

    const handleSaveClick = async() => {
        if (name.trim() === "") {
            setName(text)
            message.error("Nome non valido")
        } else if (name.trim() !== text){
            const updateTextRequestSuccess = await updateText(name.trim())
            if (updateTextRequestSuccess)
                setName(name.trim())
            else
                setName(text)
        }
        setIsEditing(false)
        setIsOneItemModifing({status: false, key: ""})
    }

    const handleModifyClick = () => {
        setIsOneItemModifing({status: true, key: id})
        setIsEditing(true)
    }

    const handleOnCancelClick = () => {
        setIsOneItemModifing({status: false, key: ""})
        setIsEditing(false)
        setName(text)
    }

    return <>
        <Row justify={'space-between'} style={{width: '100%'}}>
            <Col style={{paddingTop: !isEditing ? '0.3rem' : '0rem', marginLeft: '0.3rem', width: '70%'}}>
                {!isEditing ?
                    <Title level={5}>{name}</Title> :
                    <Input
                        ref={inputRef}
                        bordered={isEditing}
                        style={{width: '100%'}}
                        disabled={!isEditing}
                        onPressEnter={handleSaveClick}
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />}
            </Col>
            <Col>
                {!isEditing ?
                    <Button type={'link'} size={'middle'} onClick={handleModifyClick} icon={<EditOutlined/>}></Button> :
                    <Space><Button type={"default"} onClick={handleOnCancelClick}>Annulla</Button><Button type={'primary'} onClick={handleSaveClick}>Salva</Button></Space>}
            </Col>
        </Row>
    </>

}