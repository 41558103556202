import React, {useState} from "react";
import {Button, Col, Input, Modal, Row} from "antd";
import {PlusOutlined} from "@ant-design/icons";

export default function CreateNewElement({
                                             buttonContent,
                                             placeholder,
                                             modalTitle,
                                             addElement
                                         }: { buttonContent: string | undefined, placeholder: string | undefined, modalTitle: string | undefined, addElement: (newName: string) => void }) {

    const [newName, setNewName] = useState<string | undefined>(undefined)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isNameEmpty, setIsNameEmpty] = useState<boolean>(true)

    const handleClick = () => {
        if (newName) {
            addElement(newName.trim())
            setNewName(undefined)
            setIsNameEmpty(true)
            setIsModalOpen(false)
        }
    }

    const handleCancel = () => {
        console.log("CHIUSURA")
        setIsModalOpen(false)
        setIsNameEmpty(true)
        setNewName(undefined)
    }

    return <>
        <Button type={"primary"} onClick={() => setIsModalOpen(true)}
                icon={<PlusOutlined/>}>{buttonContent ? buttonContent : "Aggiungi"}</Button>
        <Modal open={isModalOpen} title={modalTitle} onCancel={handleCancel}
               footer={
                   <Row justify={'space-between'}>
                       <Col><Button type={"default"} onClick={handleCancel}>Annulla</Button></Col>
                       <Col><Button htmlType={"submit"} disabled={isNameEmpty} onClick={handleClick}
                                    type={'primary'}>{buttonContent}</Button></Col>
                   </Row>}>
            <Row><Input placeholder={placeholder ? placeholder : ""}
                        value={newName}
                        onChange={(e) => {
                            setIsNameEmpty(e.currentTarget.value.trim() === "")
                            setNewName(e.target.value)
                        }}
                        style={{width: '100%'}}
            />
            </Row>
        </Modal>
    </>
}