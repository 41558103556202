import dayjs from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';
import "dayjs/locale/it";
dayjs.locale("it");


const DatePicker = generatePicker<dayjs.Dayjs>(dayjsGenerateConfig);

export default DatePicker;