import React, {useCallback, useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import {logOut, selectCurrentToken, setCredentials} from "redux/features/authSlice"
import {useLazyGetOptionsQuery} from "redux/api/optionsApiSlice";
import {setOptions} from "redux/features/optionsSlice";
import {useLazyGetNotPaginatedReinsurersQuery} from "redux/api/reinsurersApiSlice";
import {RiskAppSpinner} from "components/spinners";
import {useLazyGetNegotiationUsersQuery} from "redux/api/negotiationUsersApiSlice";
import {setNegotiationUsers} from "redux/features/negotiationUsersSlice";
import {useLazyGetUserDataQuery} from "./redux/api/userApiSlice";
import {setChartBudgetGroup, setChartIsia, setChartUWYear, setUserData} from "./redux/features/userSlice";
import dayjs from "dayjs";
import {Modal} from "antd";
import {User} from "./types/user";

export default function AuthMiddleware({children}: { children: React.ReactElement }) {
    const accessToken = localStorage.getItem('accessToken')
    const dispatch = useDispatch()
    const [triggerOptions, {
        isLoading: isOptionsLoading,
        isUninitialized: isOptionsUninitialized
    }] = useLazyGetOptionsQuery()
    const [triggerNegotiationUsers, {
        isLoading: isNegotiationUsersLoading,
        isUninitialized: isNegotiationUsersUnitialized
    }] = useLazyGetNegotiationUsersQuery()
    const [triggerUserData, {
        isLoading: isUserDataLoading,
        isUninitialized: isUserDataUnitialized
    }] = useLazyGetUserDataQuery()

    //in order to display the spinner while the app is loading the global data
    const loaders = [
        isOptionsLoading, isOptionsUninitialized,
        isNegotiationUsersLoading, isNegotiationUsersUnitialized, isUserDataLoading, isUserDataUnitialized]

    const location = useLocation()
    const [mustReduxBeSet, setMustReduxBeSet] = useState<boolean | undefined>(undefined)
    const toRiskapp = useCallback(() => {
        dispatch(logOut())
        return null;
    }, [dispatch])

    useEffect(() => {
        // if the user isn't in /auth/ pages && redux is not set
        // ATTENTION: check is by url
        if (location.pathname && !location.pathname.includes('/auth/') && mustReduxBeSet === undefined) {
            setMustReduxBeSet(true)
        }
    }, [location.pathname, mustReduxBeSet])

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            if (accessToken) {
                try {
                    dispatch(setCredentials({user: {}, accessToken: accessToken}))

                    let userData = await triggerUserData().unwrap()
                    if (userData.preferences.charts_params){
                        if (userData.preferences.charts_params.uw_year === null) {
                            userData={...userData, preferences:{...userData.preferences, charts_params: {...userData.preferences.charts_params, uw_year: dayjs().year()}}} as User
                        }
                        if (userData.preferences.charts_params?.budget_group === null && userData?.budget_groups.length) {
                            userData={...userData, preferences:{...userData.preferences, charts_params: {...userData.preferences.charts_params, budget_group: userData.budget_groups[0]}}}
                        }
                        if (userData.preferences.charts_params?.isia === null) {
                            userData={...userData, preferences:{...userData.preferences, charts_params: {...userData.preferences.charts_params, isia: {value: 'all', label: 'ISIA e No ISIA'}}}}
                        }
                    }
                    dispatch(setUserData(userData))

                    const options = await triggerOptions().unwrap()
                    dispatch(setOptions(options))

                    const negotiationUsers = await triggerNegotiationUsers({}).unwrap()
                    dispatch(setNegotiationUsers(negotiationUsers))

                    setMustReduxBeSet(false)
                } catch (e: any) {
                    if (e.status !== 401) {
                        Modal.error({
                            title: "Errore nel caricamento dei dati",
                            content: 'Se il problema persiste contattaci all\' indirizzo support@riskapp.it',
                            onOk: () => window.location.replace((process.env.REACT_APP_URL || 'https://ayakodev.riskapp.it/webapp'))
                        })
                    }
                }
            }
        }
        if (mustReduxBeSet) {
            if (!accessToken) {
                toRiskapp()
            } else {
                checkUserLoggedIn()
            }
        }
    }, [accessToken, dispatch, mustReduxBeSet, toRiskapp, triggerNegotiationUsers, triggerOptions, triggerUserData])

    // the spinner is visible during setting redux
    if (mustReduxBeSet && accessToken && (loaders.includes(true)))
        return <RiskAppSpinner/>

    return children

}

