import { apiSlice } from "redux/api/baseApiSlice";
import { OptionsModel } from "types/options";

export const optionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOptions: builder.query<OptionsModel, void>({
            query: () => '/negotiation/negotiations/options/',
        })
    })
})

export const { useLazyGetOptionsQuery } = optionsApiSlice