import {Select, Spin, SelectProps} from "antd";
import React, {useCallback, useEffect} from "react";
import {useLazyGetContractorsQuery} from "../../../redux/api/contractorsApiSlice";
import {debounce} from "lodash";

export default function ContractorMultiSelect(props: SelectProps) {
    const [fetchContractors, { data: contractors, isFetching: isContractorsFetching, isLoading: isContractorsLoading, isError: isContractorsError }] = useLazyGetContractorsQuery()
    const debounceFetcher = useCallback(debounce( (searchTerm) => fetchContractors({search: searchTerm}), 600 ), [])

    useEffect(() => {
        fetchContractors({})
    }, [fetchContractors])

    return <Select
        labelInValue
        mode={'multiple'}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={ isContractorsLoading || isContractorsFetching ? <Spin size="small" /> : 'Nessun risultato corrispondente a questa chiave di ricerca'}
        options={contractors?.results.map(el => ({label: el.name, value: el.uuid})) || []}
        virtual={false}
        placeholder={'Digitare il nome del contraente...'}
        {...props}
    />
}


