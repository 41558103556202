import {Select, Spin, SelectProps} from "antd";
import React, {useCallback, useEffect} from "react";
import {debounce} from "lodash";
import {useLazyGetPaginatedReinsurerBrokersQuery} from "../../../redux/api/reinsurerBrokersApiSlice";

export default function ReinsurerBrokerMultiSelect(props: SelectProps) {
    const [fetchReinsurerBrokers, { data: reinsurerBrokers, isFetching: isReinsurerBrokersFetching, isLoading: isReinsurerBrokersLoading, isError: isReinsurerBrokersError }] = useLazyGetPaginatedReinsurerBrokersQuery()
    const debounceFetcher = useCallback(debounce( (searchTerm) => fetchReinsurerBrokers({search: searchTerm}), 600 ), [])

    useEffect(() => {
        fetchReinsurerBrokers({})
    }, [fetchReinsurerBrokers])

    return <Select
        labelInValue
        mode={'multiple'}
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={ isReinsurerBrokersLoading || isReinsurerBrokersFetching ? <Spin size="small" /> : 'Nessun risultato corrispondente a questa chiave di ricerca'}
        options={reinsurerBrokers?.results.map(el => ({label: el.name, value: el.uuid})) || []}
        virtual={false}
        placeholder={'Digitare il nome del broker riassicurativo...'}
        {...props}
    />
}


