import React, {useEffect, useState} from "react";
import ChartContainer from "./ChartContainer";
import {useLazyGetPortfolioStatusUploadQuery} from "../../../redux/api/chartsApiSlice";
import {useSelector} from "react-redux";
import Highcharts, {PointOptionsObject} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {selectUser} from "../../../redux/features/userSlice";

const PoliciesStatus = (props: { chartRef: React.RefObject<HighchartsReact.RefObject> }) => {
    const title = 'Stato polizze'
    const [getData, {isLoading, isFetching, isError, data}] = useLazyGetPortfolioStatusUploadQuery()
    const [series, setSeries] = useState<PointOptionsObject[]>([])
    const description = <>
        Il grafico mostra la suddivisione per stato delle polizze:
        <dl>
            <dt>Caricate:</dt>
            <dd>polizze in stato Issued caricate nel sistema portafoglio.</dd>
            <dt>Da caricare:</dt>
            <dd>polizze in stato Issued non ancora caricate nel sistema portafoglio.</dd>
            <dt>In bozza:</dt>
            <dd>polizze in stato Draft.</dd>
        </dl>
    </>

    const budgetGroup = useSelector(selectUser).preferences?.charts_params?.budget_group
    const uwYear = useSelector(selectUser).preferences?.charts_params?.uw_year
    const isia = useSelector(selectUser).preferences?.charts_params?.isia

    useEffect(() => {
        if (budgetGroup && typeof uwYear === 'number' && isia) {
            getData({
                uw_year: uwYear,
                budget_group: budgetGroup.value,
                isia: isia.value
            })
        }
    }, [budgetGroup, getData, isia, uwYear])

    useEffect(() => {
        if (data) {
            setSeries(data.map(itm => {
                return {name: itm.name, y: itm.amount}
            }))
        }
    }, [data])

    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        tooltip: {
            pointFormat: '{name} {point.percentage:.2f}%',
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            }
        },
        legend: {
            title: {text: title, style: {fontSize: "1rem", color: '#00000073', fontWeight: 'normal'}},
            // todo align text
            //labelFormat: "<span style='display: flex; justify-content: flex-end; gap:1rem; width: 100%'><span>{name}</span><span>{y:.2f}%</span></span>",
            //labelFormat: "{name} {y:.2f} %",
            //itemWidth: 200,
            //useHTML: true,
            //itemMarginTop: 17,
            //itemStyle: {"fontSize": "1rem", width: 1000},
            //width: '50%',
            align: 'right',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 50
        },
        credits: undefined,
        chart: {
            // Edit chart spacing
            spacingBottom: 0,
            spacingTop: 0,
            spacingLeft: 0,
            spacingRight: 0,
            //reflow: false
            height: '200px',
            marginLeft: 0,
            style: {position: 'relative', top: '0', left: '0'}
        },
        exporting: {enabled: false},
        series: [{
            name: undefined,
            type: 'pie',
            colors: ['#90ED7D', '#434348', '#7CB5EC'],
            data: series,
        }],
    };

    return <ChartContainer title={title} loading={isLoading || isFetching} description={description}>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={props.chartRef}
            containerProps={{style: {height: "100%", width: "100%"}}}
        />
    </ChartContainer>
}

export default PoliciesStatus