import { apiSlice } from "redux/api/baseApiSlice";
import { User } from "types/user";

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserData: builder.query<User, void>({
            query: () => '/negotiation/negotiations/user/',
        })
    })
})

export const { useLazyGetUserDataQuery } = userApiSlice