import { apiSlice } from "redux/api/baseApiSlice";
import { ReinsurerBrokerModel, GetReinsurerBrokerModel } from "types/reinsurerBroker";

export const reinsurerBrokersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotPaginatedReinsurerBrokers: builder.query<ReinsurerBrokerModel[], { search?: string }>({
            query: (args) => {
                return {
                    url: '/negotiation/reinsurerbrokers/',
                    params: {
                        paginate: false,
                        ...args
                    }
                };
            },
        }),
        getPaginatedReinsurerBrokers: builder.query<GetReinsurerBrokerModel, { search?: string, page?: number, page_size?: number }>({
            query: (args) => {
                return {
                    url: '/negotiation/reinsurerbrokers/',
                    params: {
                        paginate: true,
                        ...args
                    }
                };
            },
        }),
        createReinsurerBroker: builder.mutation<ReinsurerBrokerModel, { name: string }>({
            query: (args) => {
                const { name } = args;
                return {
                    url: '/negotiation/reinsurerbrokers/',
                    method: 'POST',
                    body: { name }
                }
            }
        }),
        updateReinsurerBroker: builder.mutation<ReinsurerBrokerModel, {name: string, uuid: string}>({
            query: (args) => {
                const { name, uuid } = args;
                return {
                    url: `/negotiation/reinsurerbrokers/${uuid}/`,
                    method: 'PATCH',
                    body: { name }
                }
            }
        })
    })
})

export const { useLazyGetNotPaginatedReinsurerBrokersQuery, useLazyGetPaginatedReinsurerBrokersQuery, useCreateReinsurerBrokerMutation, useUpdateReinsurerBrokerMutation } = reinsurerBrokersApiSlice